import { Routes } from '@angular/router';
import { AccountRoutes } from './accounts/accounts.routes';
import { authGuard } from './auth/auth.guard';
import { AUTH_ROUTES, AuthRoutes } from './auth/auth.routes';
import { JobRoutes } from './jobs/jobs.routes';
import { regionGuard } from './region/region.guard';
import { RoleRoutes } from './roles/roles.routes';
import { ScanRoutes } from './scans/scan.routes';
import { supportUserGuard } from './support-user/support-user.guard';

export const appRoutes: Routes = [
  {
    path: 'region',
    canActivate: [authGuard, regionGuard],
    canActivateChild: [authGuard],
    children: [
      {
        path: ':regionCode',
        canActivate: [supportUserGuard],
        children: [
          {
            path: ScanRoutes.Scans,
            loadChildren: () => import('./scans/scan.routes'),
          },
          {
            path: JobRoutes.Jobs,
            loadChildren: () => import('./jobs/jobs.routes'),
          },
          {
            path: '',
            redirectTo: ScanRoutes.Scans,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },

  {
    path: RoleRoutes.Roles,
    canActivateChild: [authGuard],
    loadChildren: () => import('./roles/roles.routes'),
  },
  {
    path: AccountRoutes.Accounts,
    canActivateChild: [authGuard],
    loadChildren: () => import('./accounts/accounts.routes'),
  },
  ...AUTH_ROUTES,
  {
    path: '**',
    redirectTo: `region`,
  },
  { path: '', redirectTo: AuthRoutes.Login, pathMatch: 'full' },
];
