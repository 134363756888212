import { Role } from '../roles/role.model';

export enum AppPermissions {
  viewRoles = 'viewRoles',
  createRoles = 'createRoles',
  editRoles = 'editRoles',
  viewJobs = 'viewJobs',
  editJobs = 'editJobs',
  viewScans = 'viewScans',
  viewAccounts = 'viewAccounts',
}

export interface AuthToken {
  access_token: string;
  refresh_token: string;
  id_token: string;
  scope: string;
  token_type: string;
  expires_in: number;
  expires_at: number;
}

export interface UserInfo {
  iss: string;
  sub: string;
  identity_type: string;
  given_name: string;
  family_name: string;
  email: string;
  email_verified: boolean;
  locale: string;
  preferred_mfa_setting: string;
  picture: string;
  data_region: string;
  updated_at: string;
  zoneinfo: string;
  country: string;
  role: Role;
}
